<template>
  <div
    ref="element"
    class="w-full overflow-hidden"
    @mousedown="state.isDown = true"
    @mouseup="state.isDown = false"
    @mouseleave="state.isDown = false"
    @mousemove.passive="mouseMove"
    @wheel="wheelScroll"
    @touchmove.passive="touchMove"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
const element = ref<HTMLElement | null>(null)

const state = reactive({
  isDown: false,
  previousX: 0
})

function mouseMove(e: MouseEvent) {
  // e.preventDefault()

  if (!state.isDown || !element.value) {
    return
  }

  element.value.scrollBy({
    left: e.movementX < 0 ? 10 : -10
  })
}

function wheelScroll(e: WheelEvent) {
  if (!element.value) {
    return
  }

  if (element.value.scrollWidth <= element.value.clientWidth) {
    return
  }
  e.preventDefault()
  element.value.scrollBy({
    left: e.deltaY < 0 ? 50 : -50
  })
}

function touchMove(e: TouchEvent) {
  // e.preventDefault()

  if (!element.value) {
    return
  }

  const movementX = e.touches[0].pageX - state.previousX

  element.value.scrollBy({
    left: movementX < 0 ? 10 : -10
  })

  state.previousX = e.touches[0].pageX
}
</script>
